var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "root" }, [
    _c(
      "div",
      { attrs: { id: "chart" } },
      [
        _vm.examChartOptions.isLoaded
          ? _c("apex-chart", {
              attrs: {
                type: "line",
                height: "350",
                options: _vm.examChartOptions,
                series: _vm.examSeries,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }