<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rows"
      :server-items-length="rows.length"
      hide-default-footer
      :loading="loading"
      class="elevation-1 blue-grey lighten-4"
      item-key="StudentId"
    >
      <template v-slot:[`item.Achievement`]="{ item }">
<!--        {{-->
<!--          GroupingPropertyName == 'Achievement'-->
<!--            ? item.AchievementName-->
<!--            : GroupingPropertyName == 'ParentAchievement'-->
<!--            ? item.ParentAchievementName-->
<!--            : GroupingPropertyName == 'Subject'-->
<!--            ? item.SubjectName-->
<!--            : item.ParentSubjectName-->
<!--        }}-->
          <span v-if="item.AchievementName != null">{{ item.AchievementName }}</span> 
      </template>
      <template v-slot:[`item.SelectedAnswer`]="{ item }">
        <v-chip
          small
          :class="
            item.RealAnswer.includes(item.SelectedAnswer)
              ? 'success'
              : item.SelectedAnswer == ' '
              ? 'none'
              : 'error'
          "
          >{{ item.SelectedAnswer == ' ' ? '?' : item.SelectedAnswer }}</v-chip
        >
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'subject-student-analysis',
  props: ['ExamIds', 'GroupingPropertyIds', 'GroupingPropertyName'],
  data() {
    return {
      rows: [],
      loading: true,
      headers: [
        { text: 'Sınav', value: 'ExamName' },
        { text: 'Soru No', value: 'QuestionNumber' },
        { text: 'Kazanımları', value: 'Achievement' },
        { text: 'Verilen Cevap', value: 'SelectedAnswer' },
        { text: 'Doğru Cevap', value: 'RealAnswer' }
      ]
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  watch:{
    GroupingPropertyIds(){
      this.getDataFromApi()
    }
  },
  methods: {
    getDataFromApi() {
      const model = {
        ExamIds: this.ExamIds,
        GroupingPropertyIds: this.GroupingPropertyIds,
        GroupingPropertyName: this.GroupingPropertyName
      }
      this.loading = true
      ApiService.post('api/CombinedExamReport/SubjectReport/student-achievement', model)
        .then((data) => {
          this.rows = data.data
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
