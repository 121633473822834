<template>
  <v-card>
    <v-row>
      <v-col>
        <v-select
          class="my-3 mx-3"
          v-model="currentAssessmentPointDefinitionId"
          :item-text="(item) => item.Name"
          :item-value="(item) => item.Id"
          :items="assessmentPointDefinitions"
          label="Puan Türü"
          single-line
          hide-details
          filled
          rounded
          dense
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="card card-custom">
          <!--begin::Body-->
          <div class="card-body p-3 d-flex flex-column">
            <!--begin::Stats-->
            <div class="card-spacer pt-0 bg-white flex-grow-1">
              <!--begin::Row-->
              <div class="row row-paddingless">
                <div class="col">
                  <div class="font-size-sm font-weight-bold" style="color: rgb(0, 143, 251)">
                    Öğrenci Puan Ort.
                  </div>
                  <div class="font-size-h4 font-weight-bolder">
                    {{ computedStudentPointAvg == 0 ? '---' : computedStudentPointAvg }}
                  </div>
                </div>
                <div class="col">
                  <div class="font-size-sm font-weight-bold" style="color: rgb(0, 227, 150)">
                    Genel Puan Ort.
                  </div>
                  <div class="font-size-h4 font-weight-bolder">
                    {{ computedGeneralPointAvg == 0 ? '---' : computedGeneralPointAvg }}
                  </div>
                </div>
                <div class="col">
                  <div class="font-size-sm font-weight-bold" style="color: rgb(254, 176, 25)">
                    Şube Puan Ort.
                  </div>
                  <div class="font-size-h4 font-weight-bolder">
                    {{ computedClassBranchPointAvg == 0 ? '---' : computedClassBranchPointAvg }}
                  </div>
                </div>
              </div>
              <!--end::Row-->
            </div>
            <!--end::Stats-->
          </div>
          <!--end::Body-->
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div id="chart" ref="chart">
          <apex-chart
            ref="apexchart"
            type="line"
            height="350"
            v-if="examChartOptions.isLoaded"
            :options="examChartOptions"
            :series="examSeries"
          ></apex-chart>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
export default {
  name: 'combined-result-exams',
  props: ['exams'],
  data() {
    return {
      examSeries: [
        {
          name: 'Öğrenci Puanı',
          type: 'column',
          data: []
        },
        {
          name: 'Genel Puan Ortalaması',
          type: 'line',
          data: []
        },
        {
          name: 'Şube Puan Ortalaması',
          type: 'line',
          data: []
        }
      ],
      examChartOptions: {
        isLoaded: false,
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
            endingShape: 'rounded',
            borderRadius: 3,
          }
        },
        stroke: {
          width: [0, 2, 3]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1, 2]
        },
        labels: [],
        xaxis: {
          type: 'text',
          labels: {
            rotate: -45,
            rotateAlways: true
          }
        },
        yaxis: {
          min: 100,
          max: 500,
          tickAmount: 8,
          title: {
            text: 'Puanlar'
          }
        }
      },
      assessmentPointDefinitions: [],
      search: '',
      currentAssessmentPointDefinitionId: 0,
      options: { sortBy: ['Name'] },
      examData: {
        examNets: []
      },
      computedStudentPointAvg: 0,
      computedGeneralPointAvg: 0,
      computedClassBranchPointAvg: 0,
      average: (arr) => arr.reduce((a, b) => a + b, 0) / arr.length
    }
  },
  watch: {
    async exams(val) {
      if (val.length == 0) return
      this.assessmentPointDefinitions = []
      this.currentAssessmentPointDefinitionId = 0
      await this.getAssessmentPointDefinitions()
      await this.loadChart()
    },
    async currentAssessmentPointDefinitionId() {
      await this.loadChart()
    }
  },
  async mounted() {
    await this.getAssessmentPointDefinitions()
    await this.getStudentExamGoalFromApi()
  },
  methods: {
    async loadChart() {
      this.examChartOptions.isLoaded = false
      if (
        this.currentAssessmentPointDefinitionId == 0 ||
        this.exams == null ||
        this.exams.length == 0
      )
        return

      this.examChartOptions.labels = this.exams
        .map((s) => {
          return s.Name
        })
        .filter((item, i, ar) => ar.indexOf(item) === i)
      await this.getPointReportByExamListFromApi()
      await this.getPointReportByExamClassBranchListFromApi()
      await this.getPointReportByExamAndStudentFromApi()
      this.examChartOptions.isLoaded = true
    },
    async getPointReportByExamListFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/PointAnalysis/PointReportByExamList',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            }),
            AssessmentPointDefinitionId: this.currentAssessmentPointDefinitionId
          }
        )

        var exams = data.data
        var serieData = []
        var avgData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Point.toFixed(2))
            avgData.push(exam[0].Point)
          } else {
            serieData.push(0)
            avgData.push(0)
          }
        }
        this.computedGeneralPointAvg = this.average(avgData).toFixed(2)
        this.examSeries[1] = {
          name: 'Genel Puan Ortalaması',
          type: 'line',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    async getPointReportByExamClassBranchListFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/PointAnalysis/PointReportByExamClassBranchList',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            }),
            AssessmentPointDefinitionId: this.currentAssessmentPointDefinitionId
          }
        )

        var exams = data.data
        var serieData = []
        var avgData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Point.toFixed(2))
            avgData.push(exam[0].Point)
          } else {
            serieData.push(0)
            avgData.push(0)
          }
        }
        this.computedClassBranchPointAvg = this.average(avgData).toFixed(2)
        this.examSeries[2] = {
          name: 'Şube Puan Ortalaması',
          type: 'line',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },

    async getAssessmentPointDefinitions() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post('api/CombinedExamResultReport/AssessmentPointDefinition', {
          sortBy: this.options.sortBy,
          descending: this.options.sortDesc,
          rowsPerPage: -1,
          page: this.options.page ?? 1,
          searchVal: this.search,
          Query: {
            ExamIds: this.exams.map((s) => {
              return s.Id
            })
          }
        })
        this.assessmentPointDefinitions = data.data.Results
        if (this.assessmentPointDefinitions.length == 1)
          this.currentAssessmentPointDefinitionId = this.assessmentPointDefinitions[0].Id
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    async getPointReportByExamAndStudentFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/PointAnalysis/PointReportByExamAndStudent',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            }),
            AssessmentPointDefinitionId: this.currentAssessmentPointDefinitionId
          }
        )
        var exams = data.data
        var avgData = []

        var serieData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Point.toFixed(2))
            avgData.push(exam[0].Point)
          } else {
            serieData.push(0)
            avgData.push(0)
          }
        }
        this.computedStudentPointAvg = this.average(avgData).toFixed(2)
        this.examSeries[0] = {
          name: 'Öğrenci Puanı',
          type: 'column',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    async getStudentExamGoalFromApi() {
      try {
        var data = await ApiService.get('api/StudentExamGoal/' + 0)
        if (data.data) {
          let annotations = {
            yaxis: [
              {
                y: data.data.MinPoint,
                width: '4000px',
                offsetX: -500,
                strokeDashArray: 0,
                borderWidth: 2,
                borderColor: '#E53935',
                label: {
                  offsetX: 70,
                  borderColor: '#E53935',
                  style: {
                    color: '#fff',
                    background: '#E53935'
                  },
                  //text: `HEDEF: ${data.data.Name} - Min: ${data.data.MinPoint} `
                  text: `Min: ${data.data.MinPoint} `
                }
              },
              {
                y: data.data.MaxPoint,
                width: '4000px',
                offsetX: -500,
                strokeDashArray: 0,
                borderWidth: 2,
                borderColor: '#E53935',
                label: {
                  offsetX: 70,
                  borderColor: '#E53935',
                  style: {
                    color: '#fff',
                    background: '#E53935'
                  },
                  //text: `HEDEF: ${data.data.Name} - Max: ${data.data.MaxPoint} `
                  text: `Max: ${data.data.MaxPoint} `
                }
              }
            ]
          }

          this.examChartOptions.annotations = annotations
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    }
  }
}
</script>
