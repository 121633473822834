<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rows"
      :search="search"
      :options.sync="options"
      :server-items-length="totalRows"
      :loading="loading"
      class="elevation-1"
      mobile-breakpoint="0"
      dense
      hide-default-footer
    >
      <template v-slot:[`item.ExamStartDate`]="{ item }">
        {{ item.ExamStartDate | moment }}
      </template>
      <template v-slot:[`item.IsAbsent`]="{ item }">
        <v-icon v-if="item.IsAbsent">mdi-close</v-icon>
        <v-icon v-else>mdi-check</v-icon>
      </template>
      <template v-slot:no-data>
        <h3 class="my-2">Öğrenci tüm sınavlara katılmıştır</h3>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  props: [],
  data() {
    return {
      search: '',
      totalRows: 0,
      rows: [],
      loading: true,
      options: { sortBy: ['ExamStartDate'] },
      headers: [
        { text: '#', value: 'Id' },
        { text: 'Sınav Adı', value: 'ExamName' },
        { text: 'Başlangıç Tarihi', value: 'ExamStartDate' },
        { text: 'Türü', value: 'ExamKindName' },
        { text: 'Devamsızlık', value: 'IsAbsent', align: 'center' }
      ]
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY') : ''
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/CombinedExamResultReport/ExamStudents', {
        sortBy: this.options.sortBy,
        descending: this.options.sortDesc,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page ?? 1,
        searchVal: this.search,
        Query: { OnlyAbsent: true }
      })
        .then((data) => {
          this.rows = data.data.Results
          this.totalRows = data.data.TotalNumberOfRecords
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
