<template>
  <v-card>
    <v-tabs
      horizontal
      v-model="tabSelector"
      grow
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab href="#tab-1">
        <v-icon left> mdi-book-variant </v-icon>
        SINAVLARA GÖRE
      </v-tab>
      <v-tab href="#tab-2">
        <v-icon left> mdi-table </v-icon>
        ANALİZ
      </v-tab>
      <v-tab href="#tab-3">
        <v-icon left> mdi-notebook </v-icon>
        DERSLERE GÖRE
      </v-tab>

      <v-tabs-items touchless v-model="tabSelector">
        <v-tab-item :value="'tab-1'">
          <v-card flat>
            <v-card-text>
              <div id="chart">
                <apex-chart
                  type="line"
                  height="350"
                  v-if="examChartOptions.isLoaded"
                  :options="examChartOptions"
                  :series="examSeries"
                ></apex-chart>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :value="'tab-2'">
          <v-card flat>
            <v-card-text height="500px">
              <Pivot
                :data="pivotData.rows"
                v-model="pivotData.fields"
                :reducer="pivotData.reducer"
                :available-fields-label-text="pivotData.availableFieldText"
                :no-data-warning-text="pivotData.noDataWarningText"
                :rows-label-text="pivotData.rowsLabelText"
                :cols-label-text="pivotData.colsLabelText"
                :defaultShowSettings="pivotData.defaultShowSettings"
                hide-settings-text="Ayarları Gizle"
                show-settings-text="Ayarları Göster"
                :table-height="pivotData.tableHeight"
                :fields="pivotData.fields"
                :row-field-keys="pivotData.rowFieldKeys"
                :col-field-keys="pivotData.colFieldKeys"
                :is-data-loading="!pivotData.isDataLoaded"
              >
                <template slot="value" slot-scope="{ value }">
                  <p v-if="value">{{ value.Net }} / {{ value.QuestionCount }}</p>
                </template>
                <template slot="ExamName" slot-scope="{ value }">
                  {{ value.substring(8, value.length) }}
                </template>
              </Pivot>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :value="'tab-3'">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="selectedLessonGroupId"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="lessonGroups"
                    :disabled="lessonGroups.length < 1"
                    label="Ders"
                    single-line
                    hide-details
                    filled
                    rounded
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <LessonGroupResults
                :lessonGroup="selectedLessonGroup"
                :exams="exams"
              ></LessonGroupResults>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import LessonGroupResults from '@/view/components/ExamReport/LessonGroupResults.vue'
import Pivot from '@/view/components/CorePivot.vue'
export default {
  name: 'combined-result-exams',
  props: ['exams'],
  data() {
    return {
      tabSelector: null,
      examSeries: [
        {
          name: 'Öğrenci Neti',
          type: 'column',
          data: []
        },
        {
          name: 'Genel Net Ortalaması',
          type: 'line',
          data: []
        },
        {
          name: 'Şube Net Ortalaması',
          type: 'line',
          data: []
        }
      ],
      examChartOptions: {
        isLoaded: false,
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '40%',
            endingShape: 'rounded',
            borderRadius: 3,
          }
        },
        stroke: {
          width: [0, 2, 3]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1, 2]
        },
        labels: [],
        xaxis: {
          type: 'text',
          labels: {
            rotate: -45,
            rotateAlways: true
          }
        },
        yaxis: [
          {
            max: 100,
            min: 0,
            title: {
              text: 'Netler'
            }
          }
        ]
      },
      totalRows: 0,
      LessonBranchId: 0,
      rows: [],
      lessonbranches: [],
      loading: true,
      options: { sortBy: ['StartDate'] },
      examData: {
        examNets: []
      },
      pivotData: {
        rows: [],
        fields: [
          {
            key: 'ExamName',
            getter: (item) => item.ExamNameWithDate,
            headerSlotName: 'ExamName',
            label: 'Sınav'
          },
          {
            key: 'ExamResultLessonGroupName',
            getter: (item) => item.ExamResultLessonGroupName,
            label: 'Ders'
          },
          {
            key: 'AssessmentPointCalculationTypeName',
            getter: (item) => item.AssessmentPointCalculationTypeName,
            label: 'Puan Tipi'
          }
        ],
        rowFieldKeys: ['ExamName'],
        colFieldKeys: ['ExamResultLessonGroupName', 'AssessmentPointCalculationTypeName'],
        reducer: (sum, item) => item,
        fieldsOrder: {},
        noDataWarningText: 'Görüntülenebilecek veri yok!',
        availableFieldText: 'Kullanılabilir Alanlar',
        rowsLabelText: 'Satır Alanı',
        colsLabelText: 'Sütun Alanı',
        tableHeight: '1000px',
        isDataLoaded: false,
        defaultShowSettings: false
      },
      editedIndex: -1,
      editedItem: {
        CombinedResultId: this.$route.params.id
      },
      defaultItem: {
        CombinedResultId: this.$route.params.id
      },
      lessonGroups: [],
      selectedLessonGroupId: []
    }
  },
  components: {
    Pivot,
    LessonGroupResults
  },
  computed: {
    selectedLessonGroup() {
      return this.lessonGroups.filter((s) => this.selectedLessonGroupId == s.Id)
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Ekle' : 'Düzenle'
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    async exams(val) {
      if (val.length == 0) return

      this.pivotData.isDataLoaded = false
      this.examChartOptions.isLoaded = false
      this.examChartOptions.labels = val.map((s) => {
        return s.Name
      })
      await this.getNetReportByExamAndResultGroupFromApi()
      await this.getNetReportByExamListFromApi()
      await this.getNetReportByExamClassBranchListFromApi()
      await this.getNetReportByExamAndStudentFromApi()
      this.pivotData.isDataLoaded = true
      this.examChartOptions.isLoaded = true
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.ExamId = null
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async getNetReportByExamListFromApi() {
      this.pivotData.isDataLoaded = false
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/NetAnalysis/NetReportByExamList',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            })
          }
        )

        var exams = data.data
        var serieData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Net.toFixed(2))
          } else {
            serieData.push(0)
          }
        }
        this.examChartOptions.yaxis[0].max = exams[0].QuestionCount
        this.examChartOptions.yaxis[0].min = 0
        this.examSeries[1] = {
          name: 'Genel Net Ortalaması',
          type: 'line',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    async getNetReportByExamClassBranchListFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/NetAnalysis/NetReportByExamClassBranchList',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            })
          }
        )

        var exams = data.data
        var serieData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Net.toFixed(2))
          } else {
            serieData.push(0)
          }
        }
        this.examSeries[2] = {
          name: 'Şube Net Ortalaması',
          type: 'line',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    async getNetReportByExamAndResultGroupFromApi() {
      await ApiService.setHeader()
      try {
        var examResultGroupData = await ApiService.post(
          'api/CombinedExamResultReport/NetAnalysis/NetReportByExamAndResultGroup',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            })
          }
        )
        this.pivotData.rows = examResultGroupData.data
        //find lessongroups in these selected exams
        var lessonGroupArray = examResultGroupData.data.map((x) => {
          return {
            Id: x.ExamResultLessonGroupId,
            Name: x.ExamResultLessonGroupName,
            ExamId: x.ExamId,
            ExamName: x.ExamName
          }
        })
        this.lessonGroups = lessonGroupArray
      } catch (response) {
        ApiService.showError(response.response)
      }
    },
    async getNetReportByExamAndStudentFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/NetAnalysis/NetReportByExamAndStudent',
          {
            sortBy: this.options.sortBy,
            descending: this.options.sortDesc,
            rowsPerPage: -1,
            page: this.options.page ?? 1,
            searchVal: this.search,
            Query: {
              ExamIds: this.exams.map((s) => {
                return s.Id
              })
            }
          }
        )
        var exams = data.data.Results
        var serieData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Net.toFixed(2))
          } else {
            serieData.push(0)
          }
        }
        this.examSeries[0] = {
          name: 'Öğrenci Netleri',
          type: 'column',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
      }
    }
  }
}
</script>
