var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.rows,
          search: _vm.search,
          options: _vm.options,
          "server-items-length": _vm.totalRows,
          loading: _vm.loading,
          "mobile-breakpoint": "0",
          dense: "",
          "hide-default-footer": "",
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.ExamStartDate`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_vm._f("moment")(item.ExamStartDate)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.IsAbsent`,
              fn: function ({ item }) {
                return [
                  item.IsAbsent
                    ? _c("v-icon", [_vm._v("mdi-close")])
                    : _c("v-icon", [_vm._v("mdi-check")]),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _c("h3", { staticClass: "my-2" }, [
                    _vm._v("Öğrenci tüm sınavlara katılmıştır"),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }