<template>
  <div class="root">
    <div id="chart">
      <apex-chart
        type="line"
        height="350"
        v-if="examChartOptions.isLoaded"
        :options="examChartOptions"
        :series="examSeries"
      ></apex-chart>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
export default {
  name: 'lesson-group-results',
  props: ['lessonGroup', 'exams'],
  data() {
    return {
      options: { sortBy: ['StartDate'] },
      examChartOptions: {
        isLoaded: false,
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            endingShape: 'rounded',
            borderRadius: 3,
          }
        },
        stroke: {
          width: [0, 2, 3]
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1, 2]
        },
        labels: [],
        xaxis: {
          type: 'text',
          labels: {
            rotate: -45,
            rotateAlways: true
          }
        },
        yaxis: [
          {
            title: {
              text: 'Soru Sayısı'
            },
            min: 0
          }
        ]
      },
      examSeries: [
        {
          name: 'Öğrencinin Netleri',
          type: 'column',
          data: []
        },
        {
          name: 'Genel Ortalama',
          type: 'line',
          data: []
        },
        {
          name: 'Sınıf Şube Ortalama',
          type: 'line',
          data: []
        }
      ]
    }
  },
  watch: {
    async exams(val) {
      if (val.length == 0) return

      this.examChartOptions.isLoaded = false
      this.examChartOptions.labels = val.map((s) => {
        return s.ExamName
      })
      await this.getNetReportByExamListFromApi()
      await this.getNetReportByExamClassBranchListFromApi()
      await this.getNetReportByExamAndStudentFromApi()
      this.examChartOptions.isLoaded = true
    },
    async lessonGroup(val) {
      if (val.length == 0) return
      this.examChartOptions.isLoaded = false
      this.examChartOptions.labels = val.map((s) => {
        return s.ExamName
      })
      await this.getNetReportByExamListFromApi()
      await this.getNetReportByExamClassBranchListFromApi()
      await this.getNetReportByExamAndStudentFromApi()

      this.examChartOptions.isLoaded = true
    }
  },
  methods: {
    async getNetReportByExamListFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/NetAnalysis/NetReportByExamList',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            }),
            LessonGroupId: this.lessonGroup[0].Id
          }
        )
        var exams = data.data
        this.examChartOptions.yaxis[0].max = exams[0].QuestionCount
        var serieData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Net.toFixed(2))
          } else {
            serieData.push(0)
          }
        }
        this.examSeries[1] = {
          name: 'Genel Net Ortalaması',
          type: 'line',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    async getNetReportByExamClassBranchListFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/NetAnalysis/NetReportByExamClassBranchList',
          {
            ExamIds: this.exams.map((s) => {
              return s.Id
            }),
            LessonGroupId: this.lessonGroup[0].Id
          }
        )
        var exams = data.data
        var serieData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Net.toFixed(2))
          } else {
            serieData.push(0)
          }
        }
        this.examSeries[2] = {
          name: 'Şube Net Ortalaması',
          type: 'line',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },
    async getNetReportByExamAndStudentFromApi() {
      await ApiService.setHeader()
      try {
        var data = await ApiService.post(
          'api/CombinedExamResultReport/NetAnalysis/NetReportByExamAndStudent',
          {
            sortBy: this.options.sortBy,
            descending: this.options.sortDesc,
            rowsPerPage: -1,
            page: this.options.page ?? 1,
            searchVal: this.search,
            Query: {
              ExamIds: this.exams.map((s) => {
                return s.Id
              }),
              LessonGroupId: this.lessonGroup[0].Id
            }
          }
        )
        var exams = data.data.Results
        var serieData = []
        for (let i = 0; i < this.examChartOptions.labels.length; i++) {
          var examName = this.examChartOptions.labels[i]
          var exam = exams.filter((s) => s.ExamName == examName)
          if (exam.length > 0) {
            serieData.push(exam[0].Net.toFixed(2))
          } else {
            serieData.push(0)
          }
        }
        this.examSeries[0] = {
          name: 'Öğrenci Netleri',
          type: 'column',
          data: serieData
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    }
  }
}
</script>
