<template>
  <div>
    <div class="row">
      <div class="col">
        <v-card flat>
          <v-card-title>
            Sınav Seçimi
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="px-1">
            <v-container fluid>
              <v-row>
                <v-col>
                  Başlangıç Tarihi
                  <b-form-datepicker
                    id="datepicker-dateformat1"
                    start-weekday="1"
                    ref="date1"
                    class="inline"
                    @input="getExamsFromApi"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="tr"
                    v-model="examFilter.StartDate"
                  ></b-form-datepicker>
                  <!-- <c-date-picker @change="getExamsFromApi" v-model="examFilter.StartDate" /> -->
                </v-col>
                <v-col>
                  Bitiş Tarihi
                  <b-form-datepicker
                    id="datepicker-dateformat2"
                    start-weekday="1"
                    ref="date2"
                    @input="getExamsFromApi"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="tr"
                    v-model="examFilter.EndDate"
                  ></b-form-datepicker>
                  <!-- <c-date-picker @change="getExamsFromApi" v-model="examFilter.EndDate" /> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="selectedExamIds"
                    :item-text="(item) => item.ExamName + ' [' + moment(item.ExamStartDate) + ']'"
                    :item-value="(item) => item.ExamId"
                    :items="exams"
                    label="Girmiş Olduğu Sınavlar"
                    multiple
                    clearable
                    outlined
                    dense
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small outlined color="primary" class="mt-1 ml-1">{{
                        item.ExamName
                      }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="selectAll"
                    label="Bütün Sınavları Seç"
                    hide-details
                    @change="selectAllExams(selectAll)"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- v-tabs -->
    <div class="row">
      <div v-show="selectedExamIds && selectedExamIds.length > 0" class="col-md-12">
        <!-- main table -->
        <v-tabs
          v-model="tabSelector"
          background-color="success"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          grow
          dark
          hide-slider
        >
          <v-tab href="#tab-1"> NET ANALİZİ</v-tab>
          <v-tab href="#tab-2"> KONU SORU ANALİZİ</v-tab>
          <v-tab href="#tab-3"> PUAN ANALİZİ</v-tab>
          <v-tab href="#tab-4"> KATILMADIĞI SINAVLAR</v-tab>
        </v-tabs>
        <v-tabs-items touchless v-model="tabSelector">
          <v-tab-item :value="'tab-1'">
            <CombinedResultNetAnalysisWidget :exams="selectedExams" />
          </v-tab-item>
          <v-tab-item :value="'tab-2'">
            <CombinedResultSubjectReportWidget :exams="selectedExams" />
          </v-tab-item>
          <v-tab-item :value="'tab-3'">
            <CombinedResultPointAnalysisWidget :exams="selectedExams" />
          </v-tab-item>
          <v-tab-item :value="'tab-4'">
            <AbsentExamsWidget></AbsentExamsWidget>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div v-show="!selectedExamIds || selectedExamIds.length == 0" class="col-12">
        <div class="text-center">
          <b-alert class="my-3" show variant="success">Raporu görmek için sınav seçiniz</b-alert>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import CombinedResultSubjectReportWidget from '@/view/components/ExamReport/CombinedResultSubjectReportWidget.vue'
import CombinedResultNetAnalysisWidget from '@/view/components/ExamReport/CombinedResultNetAnalysisWidget.vue'
import CombinedResultPointAnalysisWidget from '@/view/components/ExamReport/CombinedResultPointAnalysisWidget.vue'
import AbsentExamsWidget from '@/view/components/ExamReport/AbsentExamsWidget.vue'
import moment from 'moment'

export default {
  name: 'combined-result',
  components: {
    CombinedResultSubjectReportWidget,
    CombinedResultNetAnalysisWidget,
    CombinedResultPointAnalysisWidget,
    AbsentExamsWidget
  },
  data() {
    return {
      exams: [],
      tabSelector: null,
      selectedExamIds: [],
      examFilter: {
        StartDate: moment().subtract(6, 'months').format(),
        EndDate: moment().format()
      },
      loading: true,
      dialog: false,
      search: '',
      reveal: false,
      rows: [],
      selectAll: false,
      options: {},
      headers: [
        { text: '#', value: 'Id' },
        { text: 'Öğrenci Adı', value: 'FullName' },
        { text: 'İşlemler', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    selectedExams() {
      var examids = this.selectedExamIds
      return this.exams
        .filter((s) => examids.indexOf(s.ExamId) > -1)
        .map((s) => {
          return {
            Id: s.ExamId,
            Name: s.ExamName,
            StartDate: s.ExamStartDate
          }
        })
    }
  },
  mounted() {
    this.getExamsFromApi()
  },
  methods: {
    selectAllExams(status) {
      if (status) {
        this.selectedExamIds = this.exams.map((x) => {
          if (!x.disabled) return x.ExamId
        })
      } else {
        this.selectedExamIds = []
      }
    },
    moment: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    getExamsFromApi() {
      this.exams = []
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/CombinedExamResultReport/ExamStudents', {
        sortBy: ['ExamStartDate'],
        descending: [true],
        rowsPerPage: -1,
        page: 1,
        searchVal: this.search,
        Query: {
          StartDate: this.examFilter.StartDate,
          EndDate: this.examFilter.EndDate
        }
      })
        .then((data) => {
          let exams = data.data.Results
          exams.map((x) => {
            x.Id = x.ExamId + '_' + x.StudentId
            if (x.IsAbsent) {
              x.Name = x.Name + ' (Sınava girmedi)'
              x.disabled = true
            }
          })
          let examids = exams.map((s) => s.ExamId)
          examids = examids.filter(this.arrayOnlyUnique)
          examids.map((s) => this.exams.push(exams.find((d) => d.ExamId == s)))
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    arrayOnlyUnique(value, index, self) {
      return self.indexOf(value) === index
    }
  }
}
</script>
